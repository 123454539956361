import React, { useState } from 'react'

const apiUrl = 'https://back-end.guimf.workers.dev/posts'

export default function MakePost() {
  const [jsonData, setJsonData] = useState([])

  const handleTitleChange = event => {
    setJsonData({
      title: event.target.value,
      username: jsonData.username,
      content: jsonData.content,
    })
  }
  const handleUsernameChange = event => {
    setJsonData({
      title: jsonData.title,
      username: event.target.value,
      content: jsonData.content,
    })
  }
  const handleContentChange = event => {
    setJsonData({
      title: jsonData.title,
      username: jsonData.username,
      content: event.target.value,
    })
  }

  const handleSubmit = async () => {
    const data = JSON.stringify(jsonData)
    await fetch(apiUrl, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
      body: data,
    })
    window.location.reload(false)
    setJsonData({
      title: 'Request sent',
      username: '',
      content:
        'Please wait a couple of seconds before refreshing the web page, (about 5 or 10)\nthank you :)',
    })
  }

  return (
    <div className="makePost">
      <div className="makePostHeader">
        <div>
          <textarea
            value={jsonData.title}
            onChange={handleTitleChange}
            id="Title"
            name="Title"
            maxLength="20"
            className="makePostHeaderTitle makePostLable"
            placeholder="Title: "
          ></textarea>
        </div>
        <div>
          <textarea
            id="Username"
            value={jsonData.username}
            onChange={handleUsernameChange}
            name="Username"
            maxLength="20"
            className="makePostHeaderTitle makePostLable"
            placeholder="Username: "
          ></textarea>
        </div>
        <button
          onClick={handleSubmit}
          className="makePostHeaderButton"
          type="button"
        >
          Post{' '}
        </button>
      </div>
      <div className="makePostMain">
        <textarea
          id="Content"
          value={jsonData.content}
          onChange={handleContentChange}
          name="Content"
          rows="4"
          cols="50"
          className="makePostHeaderContent makePostLable"
          placeholder="Content: "
        ></textarea>
      </div>
    </div>
  )
}
