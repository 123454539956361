import React, { useState, useEffect } from 'react'
import HeaderBar from './components/HeaderBar'
import Post from './components/Post'
import MakePost from './components/MakePost'

const apiUrl = 'https://back-end.guimf.workers.dev/posts'

export default function App() {
  const [posts, setPosts] = useState([])

  useEffect(() => {
    getPost()
  }, [])

  const getPost = async () => {
    const response = await fetch(apiUrl)
    const jsonData = await response.json()
    const parsedData = jsonData.map(post => {
      //convert string object to object so we can access properties
      return JSON.parse(post)
    })
    //sorts posts by new
    parsedData.reverse()
    setPosts(parsedData)
  }

  return (
    <div className="background">
      <HeaderBar />
      <div className="main">
        <MakePost />
        <div className="posts">
          {posts.map(post => (
            <Post
              title={post.title}
              username={post.username}
              content={post.content}
              key={post.id}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
