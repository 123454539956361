import React from 'react'

export default function Post(props) {
  return (
    <div className="post">
      <div className="postHeader">
        <p className="postFont">{props.title}</p>
        <p className="postFont">{props.username}</p>
      </div>
      <div className="postMain">
        <p className="postMainFont">{props.content}</p>
      </div>
    </div>
  )
}
